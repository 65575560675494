body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-image: url(/public/images/bg.gif);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.form {
  width: 100%;
  max-width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
}

h4 {
  text-align: center;
  font-weight: bold;
}

@media (min-width: 768px) {
  .form {
    max-width: 500px;
  }
}

.glowing-text {
  color: #fff; /* Set the text color */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Add a glowing effect with text shadow */
  animation: glow 1s infinite alternate; /* Add a glowing animation */
}

.pop-out {
  animation: pop 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards; /* Add a pop-out animation and retain the final state */
  transform-origin: center;
}

input {
  width: 100%;
}
